<template>
  <div
    class="border-light rounded p-1 mb-2 bg-light"
  >
    <div
      class="height-auto overflow-hidden"
    >
      <swiper
        v-if="dataItem.images.length"
        class="swiper-navigations"
        :options="swiperOptions"
      >
        <swiper-slide
          v-for="(img,index) in dataItem.images"
          :key="index"
        >
          <b-img
            :src="productMediaURL + img.thumb"
            fluid
            class="h-auto w-100 rounded"
          />
        </swiper-slide>
        <div
          slot="pagination"
          class="swiper-pagination"
        />
      </swiper>
      <empty-image
        v-else
        class="mx-auto"
      />
      <div class="text-center mt-1">
        <div class="font-weight-bolder text-nowrap">
          {{ dataItem.product }}
        </div>
        <div class="font-small-2 text-muted">
          {{ dataItem.product_quality }}
        </div>
        <div class="text-primary">
          {{ dataItem.price | toCurrency }} {{ dataItem.currency }}
        </div>
        <div class="mt-1">
          <b-button
            variant="primary"
            size="sm"
            block
            :disabled="!dataItem.images.length"
            @click="openProduct(dataItem.id)"
          >
            <FeatherIcon icon="EyeIcon" />
            {{ $t('Görüntüle') }}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import EmptyImage from '@/layouts/components/common/EmptyImage.vue'
import { BImg, BButton } from 'bootstrap-vue'

export default {
  name: 'CatalogItem',
  components: {
    BImg,
    BButton,
    Swiper,
    SwiperSlide,
    EmptyImage,
  },
  props: {
    dataItem: {
      type: Object,
      required: true,
    },
    openProduct: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      productMediaURL: this.$store.state.app.productMediaURL,
      swiperOptions: {
        effect: 'cube',
        grabCursor: true,
        cubeEffect: {
          shadow: false,
          slideShadows: true,
          shadowOffset: 20,
          shadowScale: 0.94,
        },
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
    }
  },
}
</script>
<style>
.swiper-pagination-bullet-active{
    background: var(--primary);
}
</style>
