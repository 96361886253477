<template>
  <div
    class="empty-image bordered border-light rounded d-flex align-items-center justify-content-center bg-light text-muted"
  >
    <div class="text-center">
      <FeatherIcon
        icon="CameraIcon"
        size="24"
      />
      <div class="mt-1">
        {{ $t('Görsel Hazırlanıyor') }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmptyImage',
}
</script>
<style>
  .empty-image{
    width: 100% !important;
    aspect-ratio: 1200 / 800 !important;
    border:1px solid #dddddd !important;
  }
</style>
