<template>
  <div>
    <b-card v-if="loading">
      <pre-loading />
    </b-card>
    <template v-else>
      <b-card no-body>
        <b-card-header>
          <b-card-title>{{ catalog.title }}</b-card-title>
          <b-button
            variant="primary"
            @click="createMessage"
          >
            <FeatherIcon icon="MessageSquareIcon" />
            Mesaj Gönder
          </b-button>
        </b-card-header>
        <b-card-body>
          <div class="text-muted mt-n2 font-small-2 mb-1">
            {{ catalog.expiry_date? moment(catalog.expiry_date).format('LL') + ' ' + $t('tarihine kadar geçerli') : '' }}
          </div>
          <b-row>
            <b-col
              v-for="item in catalog.items"
              :key="item.id"
              cols="12"
              md="3"
            >
              <catalog-item
                :data-item="item"
                :open-product="openProduct"
              />
            </b-col>
          </b-row>
        </b-card-body>
        <b-card-footer>
          <b-pagination
            v-model="currentPage"
            :total-rows="catalog.itemsCount"
            :per-page="perPage"
            first-number
            last-number
            align="center"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-card-footer>
      </b-card>
      <b-card
        v-if="likeControl"
        :title="$t('Beğendiklerim')"
      >
        <b-row>
          <template v-for="item in catalog.items">
            <b-col
              v-if="item.like_it === '1'"
              :key="item.id"
              cols="12"
              md="3"
            >
              <catalog-item
                :data-item="item"
                :open-product="openProduct"
              />
            </b-col>
          </template>
        </b-row>
      </b-card>
      <product-modal :refresh-data="getData" />
    </template>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BRow, BCol, BButton, BCardFooter, BPagination,
} from 'bootstrap-vue'
import CatalogItem from '@/views/Client/Catalogs/components/CatalogItem.vue'
import ProductModal from '@/views/Client/Catalogs/components/ProductModal.vue'
import PreLoading from '@/layouts/components/common/PreLoading.vue'

export default {
  name: 'CatalogView',
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BButton,
    BCardFooter,
    BPagination,
    CatalogItem,
    ProductModal,
    PreLoading,
  },
  data() {
    return {
      loading: true,
      currentPage: 1,
      perPage: 52,
      likeControl: false,
      limit: 52,
      start: 0,
    }
  },
  computed: {
    catalog() {
      return this.$store.getters['catalogs/dataItem']
    },
    id_catalog_tracings() {
      return this.$store.getters['catalogs/id_catalog_tracings']
    },
  },
  watch: {
    currentPage(val) {
      this.start = (val - 1) * this.perPage
      this.getData()
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.loading = true
      this.$store.dispatch('catalogs/getData', {
        id: this.$route.params.id,
        limit: this.limit,
        start: this.start,
      })
        .then(response => {
          this.loading = false
          if (response.data.items) {
            const data = response.data.items.find(e => e.like_it === '1')
            if (data) {
              this.likeControl = true
            }
          }
        })
    },
    openProduct(id) {
      this.$store.dispatch('catalogs/getItem', {
        id_catalog_items: id,
        id_catalogs: this.catalog.id,
        id_catalog_tracings: this.id_catalog_tracings,
      })
    },
    createMessage() {
      this.$store.dispatch('chatMessages/createMessage', {
        id_catalogs: this.catalog.id,
      })
        .then(response => {
          if (response) {
            this.$router.push(`/app/messages/view/${response}`)
          }
        })
    },
  },
}
</script>
